import 'ui-core/components/article-actions-bar/js/article-action-bar';

const articleActionsbar = UX.articleActionsbar;

// select new feature items
articleActionsbar.sectionsTitleCheckboxes = document.querySelectorAll('.titled_issues__checkbox');

// tweaked core function to reuse it
articleActionsbar.allItemsSelected = checkboxesArray => {
    return Array.from(checkboxesArray).every(item => {
        return item.checked;
    });
};

articleActionsbar.controls = () => {
    articleActionsbar.viewAbstract = articleActionsbar.form.querySelectorAll('.accordion__control');

    if (config.enableArticleActionbarSelectAll) {
        articleActionsbar.selectAllBox.addEventListener('click', function () {
            const isChecked = this.checked;
            articleActionsbar.checkboxes.forEach(item => (item.checked = isChecked));
            articleActionsbar.sectionsTitleCheckboxes.forEach(item => (item.checked = isChecked));
            articleActionsbar.hideBtnsOrDisableSelect();
        });
    }

    // event to all sections main checkbox to select all checkboxes in the section
    articleActionsbar.sectionsTitleCheckboxes.forEach(sectionTitleCheckbox => {
        // select issues checkboxes inside this section
        let innerCheckboxes = sectionTitleCheckbox.closest('.titled_issues').querySelectorAll('.issue-Item__checkbox');

        // add listener to the title checkbox to select and deselect all boxes inside the section
        sectionTitleCheckbox.addEventListener('click', () => {
            const isChecked = sectionTitleCheckbox.checked;

            innerCheckboxes.forEach(box => (box.checked = isChecked));
            articleActionsbar.selectAllBox.checked = articleActionsbar.allItemsSelected(articleActionsbar.checkboxes);
        });

        // add listener to issues checkboxes to check/uncheck title checkbox
        innerCheckboxes.forEach(box => {
            box.addEventListener('change', () => {
                sectionTitleCheckbox.checked = articleActionsbar.allItemsSelected(innerCheckboxes);
            });
        });
    });

    articleActionsbar.checkboxes.forEach(elem => {
        elem.addEventListener('click', () => {
            articleActionsbar.selectAllBox.checked = articleActionsbar.allItemsSelected(articleActionsbar.checkboxes);
            articleActionsbar.hideBtnsOrDisableSelect();
        });
    });

    $(articleActionsbar.bulkAction).on('change', function (e) {
        const _this = this;

        if (_this.value === 'showHide') {
            if (articleActionsbar.selectAllBox.checked === true) {
                articleActionsbar.viewAbstract.forEach(item => {
                    item.click();
                });
            } else if (articleActionsbar.hasSelectedItems) {
                articleActionsbar.checkboxes.forEach(item => {
                    if (item.checked) {
                        $(item).closest('.search__item').find('.accordion__control').click();
                    }
                });
            }
            articleActionsbar.bulkAction.selectedIndex = 0;
        } else if (_this.value !== '0') {
            articleActionsbar.form.action = _this.value;
            articleActionsbar.form.submit();
        }
    });
};
